function get(url) {
    return fetch(url)
        .then(checkAuth)
        .then(parseJSON);
}

function post(url, data) {
    return fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(checkAuth)
        .then(parseJSON);
}

function put(url, data) {
    return fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(checkAuth)
        .then(parseJSON);
}

function patch(url, data) {
    return fetch(url, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    })
        .then(checkAuth)
        .then(parseJSON);
}

function del(url) {
    return fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then(checkAuth)
        .then(parseJSON);
}

function checkAuth(response) {
    return new Promise(async (resolve, reject) => {
        if (response.status === 401) window.location.assign("/");
        else resolve(response);
    })
}

function parseJSON(response) {
    return response.json();
}

export default {
    get,
    post,
    put,
    patch,
    del
}