import React, {useEffect, useState} from "react";
import { create, get } from "@github/webauthn-json/browser-ponyfill";
import Client from "../remote/client";

export function Login({label, onFailure, onSuccess}) {
    const [passkeySupported, didDetermine] = detectPasskeySupport();

    if (passkeySupported)
        return <div className="d-grid gap-2 mb-2">
            <button type="button" className="btn btn-lg btn-danger"
                    onClick={() => authenticate(onFailure, onSuccess)}>{label}</button>
        </div>

    return <></>;
}

export function AddPassKey({onPasskeyAdded}) {
    const [passkeySupported, didDetermine] = detectPasskeySupport();

    if (!didDetermine) return <></>;

    return <>
        <button className="btn btn-primary mb-2" disabled={!passkeySupported} onClick={() => register(onPasskeyAdded)}>Add Passkey</button>
        {!passkeySupported ? <p>Passkey is not supported on this browser</p> : <></>}
    </>;

    if (passkeySupported)
        return <button className="btn btn-primary" onClick={() => register(onPasskeyAdded)}>Add Passkey</button>;
    else return <></>;
}

function detectPasskeySupport() {
    const [didDetermine, setDidDetermine] = useState(false);
    const [passkeySupported, setPasskeySupported] = useState(false);

    useEffect(() => {
        (async () => {
            setPasskeySupported(await checkPassKeySupport());
            setDidDetermine(true);
        })()
    }, []);

    return [passkeySupported, didDetermine];
}

function checkPassKeySupport() {
    const hasAPIAvailability =
        window.PublicKeyCredential &&
        PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable &&
        PublicKeyCredential.isConditionalMediationAvailable

    if (hasAPIAvailability)
        return Promise
            .all([
                PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
                PublicKeyCredential.isConditionalMediationAvailable()
            ])
            .then(results => results.every(_ => _ === true));
    else return Promise.resolve(false);
}

function uint8FromBase64(text) { return uint8FromString(atob(text)); }

function uint8FromString(text) { return Uint8Array.from(text, c => c.charCodeAt(0)); }

async function register(onPasskeyAdded) {
    const opts = await loadCreateOptions();
    const credential = await create({ publicKey: opts });
    await validateRegistration(credential);
    onPasskeyAdded();
}

async function authenticate(onFailure, onSuccess) {
    try {
        const opts = await loadAuthenticationOptions();
        const credential = await get({ publicKey: opts });
        const validationResult = await validateAuthentication(credential);
        if (validationResult?.error?.code) onFailure(validationResult.error.code)
        else onSuccess()
    } catch (e) {
        onFailure(e.name);
    }
}

async function loadCreateOptions() {
    const params = (await Client.post("/passkey/beginRegistration", {})).resource
    // unpack base64
    params.challenge = uint8FromBase64(params.challenge);
    params.user.id = uint8FromString(params.user.id);
    return params;
}

async function validateRegistration(credential) {
    const json = credential.toJSON();
    await Client.post("/passkey/finishRegistration", json);
}

async function validateAuthentication(credential) {
    const json = credential.toJSON();
    return await Client.post("/passkey/finishAuthentication", json);
}

async function loadAuthenticationOptions() {
    const params = (await Client.post("/passkey/beginAuthentication", {})).resource;
    params.challenge = uint8FromBase64(params.challenge);
    return params;
}
